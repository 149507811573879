import * as Sentry from '@sentry/browser';

if (process.env.NODE_ENV === 'production' && window._mt) {
  var mixpanel = require('mixpanel-browser');
  mixpanel.init(window._mt);
}

if (process.env.NODE_ENV === 'production' && window._sentry_client_dsn) {
  Sentry.init({
    dsn: window._sentry_client_dsn,
  });
}
